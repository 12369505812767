<template>
  <div class="CardMemo">
    <button type="button" @click="emit('close')">
      <font-awesome-icon :icon="faXmark"/>
      <span class="visually-hidden">
        {{ $t('campaign.hideDetail') }}
      </span>
    </button>
    <h3>
      {{ $t(`card.memo.title`) }}
    </h3>
    <div>
      <section v-if="itemsQuantity > 0" class="CardMemoItem">
        <div class="CardMemoItem--Label">
          {{
            $tc(`card.memo.type.${form.formType.toLowerCase()}.items`, itemsQuantity, {
              nbTickets: itemsQuantity
            })
          }}
        </div>
        <div class="CardMemoItem--Price">
          {{ itemPrice > 0 ? convertToEuros(itemPrice) : $t('card.memo.free') }}
        </div>
      </section>

      <section v-if="optionsQuantity > 0" class="CardMemoItem">
        <div class="CardMemoItem--Label">
          {{
            $tc('card.memo.options', optionsQuantity, {
              nbOptions: optionsQuantity
            })
          }}
        </div>
        <div class="CardMemoItem--Price">
          {{ optionsPrice > 0 ? convertToEuros(optionsPrice) : $t('card.memo.free') }}
        </div>
      </section>

      <section v-if="donationPrice > 0" class="CardMemoItem">
        <div class="CardMemoItem--Label">
          {{ $t('card.memo.donation') }}
        </div>
        <div class="CardMemoItem--Price">
          {{ convertToEuros(donationPrice) }}
        </div>
      </section>

      <section v-if="discount > 0" class="CardMemoItem">
        <div class="CardMemoItem--Label">
          {{ $t('card.memo.discount') }}
        </div>
        <div class="CardMemoItem--Price">- {{ convertToEuros(discount) }}</div>
      </section>

      <section v-if="totalPrice > 0" class="CardMemoItem CardMemoItem--Contribute">
        <span >{{ $t('card.memo.contribute') }}</span>
        <HaBadge type="success" :icon="true">
          {{ $t('card.memo.contributeAmount') }}
          <template #icon>
            <font-awesome-icon :icon="faCircleInfo" fixed-width/>
          </template>
        </HaBadge>
      </section>

      <section v-if="totalPrice > 0"  class="CardMemoContribute">
        <p>
          {{ $t('card.memo.contributeExplanation.text1') }}
          <strong>{{ $t('card.memo.contributeExplanation.text2') }}</strong>
        </p>
        <p>{{ $t('card.memo.contributeExplanation.text3', [organization.name]) }}</p>
      </section>

      <section class="CardMemoTotal">
        {{ $t('card.memo.amount') }}
        <span class="CardMemoTotal--Price">
          {{ convertToEuros(totalPrice) }}
        </span>
      </section>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from '@nuxtjs/composition-api'
import useStoreData from '@/composables/useStoreData'
import { HaBadge } from '@ha/components'
import { filter, size, forEach, sumBy } from 'lodash-es'
import { enum as enums, functions } from '@ha/helpers'
import { itemType } from '@/helpers/enums'
import {
  faCircleInfo, faXmark
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const emit = defineEmits(['close', 'update:total-amount '])
const { storeRouteParams, organization } = useStoreData()
const store = useStore()

const cart = computed(() => {
  return store.getters['carts/getCart'](storeRouteParams.value)
})
const cartItems = computed(() => {
  return cart.value?.itemList || []
})
const form = computed(() => {
  return store.getters['forms/getForm'](storeRouteParams.value)
})

const formTypes = computed(() => {
  return enums.FormType
})
const discount = computed(() => {
  return cart.value?.paymentTerms[0]?.discount || 0
})
const itemsQuantity = computed(() => {
  return size(filterMainTiers())
})
const itemPrice = computed(() => {
  let total = 0
  const tickets = filterMainTiers()
  forEach(tickets, ticket => {
    // get payment frequency
    // return cart ticket amount, multiplied by payment frequency
    total += ticket?.customAmount || 0
  })
  return total
})
const options = computed(() => {
  const itemsWithOptions = filter(cartItems.value, function (item) {
    return item.extraOptions
  })
  const options = []
  forEach(itemsWithOptions, item => {
    options.push(
      ...filter(item.extraOptions, option => {
        return option.value
      })
    )
  })
  return options
})
const optionsQuantity = computed(() => {
  return size(options.value)
})
const optionsPrice = computed(() => {
  return sumBy(
    options.value,
    (option) => option.paymentTerms ? option.paymentTerms[0].realAmount : option.price || 0
  )
})
const donationPrice = computed(() => {
  if ([formTypes.value.CROWDFUNDING].includes(form.value.formType)) {
    return 0
  }
  const donations = filter(cartItems.value, { tierType: 'Donation' })
  return sumBy(donations, 'customAmount')
})
const totalPrice = computed(() =>  {
  emit('update:total-amount', itemPrice.value + optionsPrice.value + donationPrice.value - discount.value)
  return itemPrice.value + optionsPrice.value + donationPrice.value - discount.value
})

const convertToEuros = (price) => {
  return functions.convertToEuros(price) + ' €'
}
const filterMainTiers = () => {
  return filter(cartItems.value, {
    tierType: itemType[storeRouteParams.value.type]
  })
}

</script>

<style lang="scss" scoped>
.CardMemo {
  width: 100%;
  margin-top: $ha-spacing-large;
  padding: $ha-spacing-large;
  font-size: $ha-font-size-small;
  background-color: var(--ha-color-white);

  @include mediaQuery(900) {
    position: sticky;
    top: 1em;
    border-radius: $ha-radius-large;
    box-shadow: $ha-box-shadow-close;
  }

  button {

    @include mediaQuery(900) {
      display: none;
    }

    svg {
      height: $ha-spacing-large;
      margin-left: -6px; // there is a slight gap in the svg and its path
      color: var(--ha-color-primary);
    }
  }

  h3 {
    margin-top: $ha-spacing-small;
    color: var(--ha-color-midnight);
    font-weight: $ha-font-weight-bold;
    font-size: $ha-font-size-large;

    @include mediaQuery(900) {
      margin-top: 0;
    }
  }

  &Item {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: $ha-spacing-tiny 0;

    + .CardMemoItem {
      align-items: center;
      border-top: $ha-border-width-regular solid set-alpha(border, 0.5);
    }

    > * {
      max-width: 50%;
      font-size: $ha-font-size-small;
    }

    &--Label {
      padding-right: $ha-spacing-mini;
      overflow: hidden;
      white-space: nowrap;
      text-align: left;
      text-overflow: ellipsis;
    }

    &--Price {
      padding-left: $ha-spacing-mini;
      white-space: nowrap;
      text-align: right;
    }

    &--Contribute {
      .HaBadge {
        @include mediaQuery(900, 'between', 1200) {
          font-size: $ha-font-size-tiny;
          padding-inline: $ha-spacing-tiny;
        }
      }
    }
  }

  &Total {
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;
    justify-content: space-between;
    margin-top: $ha-spacing-tiny;
    padding-top: $ha-spacing-medium;
    font-weight: $ha-font-weight-bold;
    font-size: $ha-font-size-regular;
    border-top: $ha-border-regular;

    &--Price {
      padding-left: $ha-spacing-tiny;
      color: var(--ha-color-primary);
    }
  }

  &Contribute {
    margin-top: $ha-spacing-small;
    margin-bottom: $ha-spacing-large;
    padding: $ha-spacing-small;
    background-color: var(--ha-color-air);

    p {
      color: var(--ha-color-midnight);

      &:last-of-type {
        margin-bottom: 0;
      }
    }

  }
  // too touchy to update this component
  :deep(.HaBadge-Icon) {
    color: var(--ha-color-midnight);
  }
}
</style>
