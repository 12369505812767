var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container"},[_c(_setup.InfoText,{attrs:{"text":_vm.$t('campaign.requiredFieldsTextInfo')}}),_vm._v(" "),(Object.keys(_setup.modelsByTierId).length)?_c('div',{staticClass:"participant-step",attrs:{"data-test":"selected-price"}},_vm._l((_setup.modelsByTierIdFiltered),function(cartItemList,tierId,tierAggregationIndex){return _c('div',{key:tierId,staticClass:"tier-section"},[(_setup.getTierName(tierId) && _setup.isShopOrCrowd)?_c('h3',{staticClass:"tier-title"},[_vm._v("\n        "+_vm._s(cartItemList.length + 'x')+"\n        "+_vm._s(_setup.getTierName(tierId))+"\n      ")]):_vm._e(),_vm._v(" "),_vm._l((cartItemList),function({ participantLocalId, ...cartItem },index){return _c('div',{key:index,class:{
            'participant-step__tier': participantLocalId || cartItem.extraOptions?.length,
            'participant-step__tier--border': !_setup.isShopOrCrowd
          },attrs:{"data-test":"block-participant"}},[(_setup.getTierName(tierId) && !_setup.isShopOrCrowd)?_c('h3',{staticClass:"tier-title"},[_vm._v("\n            "+_vm._s(_setup.getTierName(tierId))+" - "+_vm._s(_vm.$t(`campaign.step.participants.type.${_setup.form.formType.toLowerCase()}.participant`))+"\n            "+_vm._s(index + 1)+"\n          ")]):_vm._e(),_vm._v(" "),_c('div',{class:[
              'participant-step__tier__field-container',
              {
                'participant-step__tier__field-container--flex': cartItem.extraOptions?.length
              }
            ],attrs:{"data-test":"block-tarif"}},[(participantLocalId)?_c(_setup.ParticipantInformation,{attrs:{"has-mandatory-information":!_setup.isShopOrCrowd,"participant-local-id":participantLocalId,"display-copy-info-btn":_setup.displayCopyInfoBtn(tierAggregationIndex, index)}}):_vm._e(),_vm._v(" "),(cartItem.extraOptions?.length)?_c('div',{attrs:{"data-test":"block-option"}},[_c('h4',{staticClass:"h5"},[_vm._v("\n                "+_vm._s(_vm.$t('campaign.step.participants.extraOptions'))+"\n              ")]),_vm._v(" "),_c(_setup.ExtraOptions,{attrs:{"cart-id":_setup.cart.id,"cart-item":cartItem,"index":index}})],1):_vm._e()],1)])})],2)}),0):_c('div',{staticClass:"participant-step-loading"},[_vm._v("Chargement des participants")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }