<template>
  <ha-modal class="ModalContributionExplication" v-on="$listeners" data-testid="cv-reason-modal">
    <template #header data-testid="title-reason-modal">
      <span data-testid="title-reason-modal">
        {{ $t('campaign.step.summary.whyContribute') }}
      </span>
    </template>
    <template #body>
      <div data-testid="content-reason-modal" class="content-reason-modal">
        <img
          src="https://cdn.helloasso.com/images/forms/cv_reassurance_1.svg"
          alt=""
          aria-hidden="true"
        />
        <p class="title-reason-modal">{{ $t('modal.contributionExplication.title') }}</p>
        <span v-html="$t('modal.contributionExplication.intro_1')" />
        <ul>
          <li v-html="$t('modal.contributionExplication.content_1')" />
          <li v-html="$t('modal.contributionExplication.content_2')" />
          <li v-html="$t('modal.contributionExplication.content_3')" />
        </ul>
      </div>
    </template>
    <template #footer>
      <ha-button
        :data-ux="`Forms_${metadata.type}${
          isWidget ? 'Widget' : ''
        }_Modal_ContributionExplication_GotIt`"
        data-testid="button-close"
        @click="$emit('close')"
      >
        {{ $t('modal.contributionExplication.cta') }}
        <span class="visually-hidden">{{ $t('modal.close') }}</span>
      </ha-button>
    </template>
  </ha-modal>
</template>

<script>
import { HaModal, HaButton } from '@ha/components'

export default {
  name: 'ModalContributionExplication',
  inject: ['isWidget'],
  components: { HaModal, HaButton }
}
</script>

<style lang="scss" scoped>
.title-reason-modal,
.content-reason-modal li {
  color: var(--ha-color-iris);
}

.title-reason-modal {
  margin-top: $ha-spacing-medium;
  font-weight: bold;
  font-size: $ha-font-size-large;
  text-align: center;
}

.ModalContributionExplication {
  font-size: $ha-font-size-small;
  text-align: left;

  ul {
    margin-top: $ha-spacing-medium;
    padding-left: $ha-spacing-big;
    list-style: disc;

    li {
      margin-bottom: $ha-spacing-medium;
    }
  }

  .content-reason-modal {
    img {
      display: block;
      margin-inline: auto;
    }
  }

  :deep(.HaModal--Header) {
    font-weight: $ha-font-weight-regular;
    font-size: $ha-font-size-large;
  }
}
</style>
