import { render, staticRenderFns } from "./CartSummaryLight.vue?vue&type=template&id=5cb0e544&scoped=true"
import script from "./CartSummaryLight.vue?vue&type=script&setup=true&lang=js"
export * from "./CartSummaryLight.vue?vue&type=script&setup=true&lang=js"
import style0 from "./CartSummaryLight.vue?vue&type=style&index=0&id=5cb0e544&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cb0e544",
  null
  
)

export default component.exports