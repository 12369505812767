<template>
  <div
    :class="{ Card: !isDonation, DonationHeader: isDonation }"
    class="CampaignHeader"
    data-testid="campaign-header"
  >
    <div class="CampaignHeader__heading">
      <h1 class="CampaignHeader--Title" data-testid="title">
        <slot name="title">{{ title }}</slot>
      </h1>
      <ClientOnly v-if="!isDonation && step === 1">
        <ShareButton />
      </ClientOnly>
    </div>
    <h2 class="CampaignHeader--Subtitle">
      <slot name="subtitle">
        <template v-if="organizationName">
          {{ byWording }}
          <ha-button
            :href="`/associations/${metadata.organization}`"
            target="_blank"
            rel="noopener noreferrer"
            variant="link"
            :data-ux="`Forms_${metadata.type}_CampaignHeader_OrganismPage`"
          >
            {{ organizationName }}
          </ha-button>
        </template>
      </slot>
    </h2>
    <span v-if="dateLabel" class="CampaignHeader--Date">
      <slot name="date">
        <fa icon="calendar-alt" />
        {{ dateLabel }}
      </slot>
    </span>
    <div
      ref="description"
      v-if="description && !form.invertDescriptions"
      :class="[
        'CampaignHeader--Description',
        {
          collapsed: !isCrowdfunding && formState.isDescriptionCollapsed,
          gradient: formState.isLongDescription
        }
      ]"
    >
      <span>{{ description }}</span>
      <FormPresentation v-if="!isCrowdfunding" :is-inverted-description="form.invertDescriptions" />
    </div>
    <div class="collapse-control" v-if="formState.isDescriptionCollapsed">
      <HaButton
        type="button"
        variant="outline"
        icon-side="right"
        size="small"
        v-if="!isCrowdfunding && formState.isLongDescription && !form.invertDescriptions"
        @click="showDescription"
      >
        {{
          formState.isDescriptionCollapsed
            ? $t('campaignHeader.displayMore')
            : $t('campaignHeader.displayLess')
        }}
        <template #icon>
          <fa :icon="formState.isDescriptionCollapsed ? 'chevron-down' : 'chevron-up'" />
        </template>
      </HaButton>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash-es'
import { HaButton } from '@ha/components'
import { getDateLabel } from '@/helpers/formUtils'
import { enum as enums, haMoment } from '@ha/helpers'
import FormPresentation from '@/components/forms/informations/FormPresentation.vue'
import useFormType from '@/composables/useFormType'
import ShareLinks from '@/components/globals/share/ShareLinks.vue'
import ShareButton from '@/components/forms/informations/partials/ShareButton.vue'
import useTracking from '~/composables/useTracking'

export default {
  name: 'CampaignHeader',
  components: {
    HaButton,
    ShareLinks,
    FormPresentation,
    ShareButton
  },
  props: {
    step: {
      type: Number,
      required: true
    }
  },
  setup() {
    const { isDonation, formState, isCrowdfunding } = useFormType()
    const { trackingTrack } = useTracking()
    return {
      isDonation,
      formState,
      trackingTrack,
      isCrowdfunding
    }
  },
  mounted() {
    if (!this.isDonation && this.$refs.description) {
      this.formState.isLongDescription = this.$refs.description.scrollHeight > this.$refs.description.clientHeight
    }
  },
  computed: {
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    },
    organization() {
      return this.$store.getters['organizations/getOrganization'](
        this.metadata.organization
      )
    },
    organizationName() {
      return get(
        this.$store.getters['organizations/getOrganization'](
          this.metadata.organization
        ),
        'name'
      )
    },
    title() {
      return get(this, 'form.title')
    },
    description() {
      return get(this, 'form.description', '')
    },
    startDate() {
      return get(this, 'form.startDate')
    },
    endDate() {
      return get(this, 'form.endDate')
    },
    formTypes() {
      return enums.FormType
    },
    isDonation() {
      return this.formTypes.DONATION === this.form.formType
    },
    dateLabel() {
      // get the right date label dependings of the type of membership
      if ([this.formTypes.MEMBERSHIP].includes(this.form.formType)) {
        let label = ''
        switch (get(this.form, 'validityType')) {
          case 'MovingYear':
            label = this.$t('campaign.date.with2Dates.withNoTime', [
              haMoment(this.startDate).format('DD MMMM YYYY'),
              haMoment(this.startDate).add(1, 'year').format('DD MMMM YYYY')
            ])
            break
          case 'Illimited':
            return ''
          case 'Custom':
            label = this.$t('campaign.date.with2Dates.withNoTime', [
              haMoment(this.startDate).format('DD MMMM YYYY'),
              haMoment(this.endDate).format('DD MMMM YYYY')
            ])
            break
          default:
            break
        }
        return `${this.$t('campaign.membershipFrom')} ${label.toLowerCase()}`
      }
      return getDateLabel(this.startDate, this.endDate, this.$i18n)
    },
    isNavigatorShare() {
      return process.client && navigator.share
    },
    byWording() {
      return this.isDonation
        ? this.$t('campaignHeader.inFavour')
        : this.$t('campaignHeader.by')
    }
  },
  methods: {
    navigatorShare() {
      if (navigator.share) {
        navigator.share({
          title: this.title,
          text: this.description,
          url: window.location.href
        })
      }
    },
    showDescription() {
      this.formState.isDescriptionCollapsed = false
      this.trackingTrack('Open Form Description Clicked', {
        form_slug: this.form.formSlug,
        form_type: this.form.formType,
        organization_slug: this.form.organizationSlug
      })
    }
  }
}
</script>

<style lang="scss">
.CampaignHeader {
  &__heading {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    word-break: break-word;

    @include mediaQuery(600) {
      word-break: unset;
    }
  }

  &--Title {
    margin-bottom: $ha-spacing-mini;
    padding-right: $ha-spacing-big;
    font-size: $ha-font-size-jumbo;
    line-height: $ha-line-height-small;
  }

  &--Subtitle {
    margin: 0;
    color: var(--ha-color-text-light);
    font-weight: $ha-font-weight-regular;
    font-size: $ha-font-size-big;

    > .HaButton {
      font-size: inherit;
    }
  }

  &--Date {
    display: block;
    margin-top: $ha-spacing-small;
    margin-bottom: $ha-spacing-mini;
    color: #505870;
    font-weight: $ha-font-weight-semibold;
    font-size: $ha-font-size-small;

    svg {
      margin-right: $ha-spacing-mini;
    }
  }

  &--Description {
    position: relative;
    margin-top: $ha-spacing-large;
    margin-bottom: 0;
    padding-top: $ha-spacing-medium;
    font-size: $ha-font-size-regular;
    white-space: pre-line;
    word-break: break-word;
    border-top: $ha-border-width-regular solid set-alpha(border, 0.5);

    &.collapsed {
      max-height: 8lh;
      overflow: hidden;
    }
  }

  &:has(.collapsed) {
    .gradient {
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 33%;
        color: transparent;
        background: linear-gradient(
          to bottom,
          transparent,
          var(--ha-color-white) 90%
        );
        content: '';
        pointer-events: none;
      }
    }
  }

  &--ShareButton {
    position: absolute;
    top: $ha-unit * 1.5; // 24px
    right: $ha-unit * 4.5; // 36px
    //width: $ha-unit * 4;
    //height: $ha-unit * 4;
    padding: $ha-spacing-small;
    color: var(--ha-color-text-light);
    outline: none;
  }

  .ShareLinks {
    margin-top: $ha-spacing-mini;
  }
}

.collapse-control {
  text-align: center;
}
</style>
