import { computed } from 'vue'
import { useSsrState } from '@ha/components'
import useStoreData from '@/composables/useStoreData'
import { formatCartItemsToSelectedTiers } from '@/components/tiers/helpers'

const selectedTiers = useSsrState('selectedTiers', () => ({}))

export default () => {
  const { compliance, cart } = useStoreData()

  const isCollectAllowed = computed(() => compliance.value?.allowCollect ?? true)
  const isSelectedTiersEmpty = computed(() => !Object.keys(selectedTiers.value).length)

  const resetOtherSelectedTiers = tier => {
    const currentTierData = selectedTiers.value[tier.id]
    selectedTiers.value = { [tier.id]: currentTierData }
  }

  const resetSelectedTiersQuantity = () => {
    const selectedTiersWithoutQuantity = Object.keys(selectedTiers.value).reduce(
      (tiers, tierId) => {
        Object.assign(tiers, {
          [tierId]: { customAmount: selectedTiers.value[tierId].customAmount }
        })
        return tiers
      },
      {}
    )
    selectedTiers.value = selectedTiersWithoutQuantity
  }

  const updateSelectedTier = (tier, data) => {
    const tierData = selectedTiers.value[tier.id] ?? {}
    Object.assign(tierData, data)
    selectedTiers.value = { ...selectedTiers.value, [tier.id]: tierData }
  }

  const setSelectedTierFromCart = () => {
    selectedTiers.value = formatCartItemsToSelectedTiers(cart.value)
  }

  const bookedTiersFromCart = computed(() => formatCartItemsToSelectedTiers(cart.value))

  return {
    selectedTiers,
    isCollectAllowed,
    isSelectedTiersEmpty,
    updateSelectedTier,
    resetOtherSelectedTiers,
    setSelectedTierFromCart,
    resetSelectedTiersQuantity,
    bookedTiersFromCart
  }
}
