var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{class:[
        { 'CampaignForm--Controls-Fixed': !_vm.isWidget },
        'SingleButton',
        { FormAdmin: _vm.isFormAdmin },
        { 'CampaignForm--Controls-Widget': _vm.isWidget },
      ],attrs:{"data-testid":"forms-steps"}},[_c('div',[(!_vm.isFirstStep && !_setup.cartHasScheduledPayments)?_c('p',[_vm._v("Total")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"price-container__price"},[(!_vm.isFirstStep && !_setup.isLastStep)?_c('button',{class:{ 'arrow-down': _setup.displaySummary },attrs:{"type":"button"},on:{"click":_setup.toggleSummary}},[_c('fa',{attrs:{"icon":"angle-up"}}),_vm._v(" "),(!_setup.displaySummary)?_c('span',{staticClass:"visually-hidden"},[_vm._v("\n            "+_vm._s(_vm.$t('campaign.openDetail'))+"\n          ")]):_c('span',{staticClass:"visually-hidden"},[_vm._v("\n            "+_vm._s(_vm.$t('campaign.hideDetail'))+"\n          ")])],1):_vm._e(),_vm._v(" "),_c(_setup.PriceAmountControls,{attrs:{"price":_vm.price,"label":_vm.label,"isWidget":_vm.isWidget,"isFormAdmin":_vm.isFormAdmin,"isLoading":_vm.isLoading,"isLastStep":_setup.isLastStep,"hasTerms":_setup.cartHasScheduledPayments}})],1)]),_vm._v(" "),_c(_setup.HaButton,{class:[
        'NextButton',
        { ValidatingButton: (!_vm.canClickNext || !_vm.validForm) },
      ],attrs:{"size":"large","icon-side":"right","loading":_vm.isLoading,"data-ux":`Forms_${_vm.formType}${_vm.isWidget ? 'Widget' : ''}_NextStep`,"data-test":"button-next-step"},on:{"click":function($event){return _setup.emit('on-button-click')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('fa',{attrs:{"icon":"angle-right"}})]},proxy:true}])},[_vm._v("\n      "+_vm._s(_vm.buttonLabel)+"\n      ")])],1),_vm._v(" "),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_setup.canDisplaySummary)?_c(_setup.CartSummaryLight,{staticClass:"CardMemo--mobile",attrs:{"total-amount":_setup.totalAmountMutation},on:{"update:totalAmount":function($event){_setup.totalAmountMutation=$event},"update:total-amount":function($event){_setup.totalAmountMutation=$event},"close":_setup.toggleSummary}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }