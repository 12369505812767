<template>
  <div
    v-if="!$device.isMobile"
    :class="[
      'Card CardNumbersSticky Card-Split',
      { 'CardNumbersSticky-Show': displayedFixedNumbers }
    ]"
  >
    <div class="CardNumbersStickyContainer Container">
      <div class="CardNumbersStickyLeft">
        <h1 class="CampaignHeader--Title">
          <slot name="title">{{ title }}</slot>
        </h1>
        <h2 class="CampaignHeader--Subtitle">
          <slot name="subtitle">
            <template v-if="organizationName">
              {{ $t('campaignHeader.by') }}
              <ha-button
                :href="`/associations/${metadata.organization}`"
                target="_blank"
                variant="link"
                :data-ux="`Forms_${metadata.type}_CampaignHeader_OrganismPage`"
                data-testid="asso-link"
              >
                {{ organizationName }}
              </ha-button>
            </template>
          </slot>
        </h2>
      </div>
      <div class="CardNumbersStickyRight">
        <FormRaisingState
          v-if="form.amountVisible || (saleEndDate && !isCampaignEnded && !isSaleEnded)"
          :sticky="true"
        />
      </div>
    </div>
  </div>
  <div v-else class="Card CardNumbersSticky-Mobile">
    <ha-button class="CardNumbers--Contribute" href="#tickets" fluid>
      {{ $t('campaign.contribute') }}
    </ha-button>
  </div>
</template>
<script>
import { get } from 'lodash-es'
import { HaButton } from '@ha/components'

import FormRaisingState from '@/components/forms/informations/FormRaisingState.vue'

export default {
  name: 'CardNumbersSticky',
  components: { HaButton, FormRaisingState },
  props: {
    isCampaignEnded: {
      type: Boolean
    },
    isSaleEnded: {
      type: Boolean
    },
    saleEndDate: {
      type: String // ISO date string
    }
  },
  data() {
    return {
      hasScrolled: false,
      displayedFixedNumbers: false
    }
  },
  computed: {
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    },
    title() {
      return get(this, 'form.title')
    },
    organization() {
      return this.$store.getters['organizations/getOrganization'](this.metadata.organization)
    },
    organizationName() {
      return get(
        this.$store.getters['organizations/getOrganization'](this.metadata.organization),
        'name'
      )
    }
  },
  mounted() {
    const observer = new IntersectionObserver(this.handleScroll, { threshold: 1 })
    observer.observe(document.querySelector('.CardNumbers'))
  },
  methods: {
    handleScroll() {
      const nav = document.querySelector('.CardNumbers')
      const navOffset = nav.getBoundingClientRect()
      // Make navbar fixed
      if (navOffset.top <= 0) {
        this.displayedFixedNumbers = true
      } else {
        this.displayedFixedNumbers = false
      }
    }
  }
}
</script>

<style lang="scss">
.AdminView {
  .CardNumbersSticky-Mobile {
    bottom: $ha-unit * 7.75; // 62px, settings bar height
  }
}

.CardNumbersSticky,
.CardNumbersSticky-Mobile {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  margin: 0 !important;
}

.CardNumbersSticky {
  top: -1px; // we don't know why
  display: none;
  border-radius: 0;

  @include mediaQuery(900) {
    border-top: $ha-border-width-regular solid set-alpha('border', 0.5);
    box-shadow: 0 ($ha-unit * 1.25) ($ha-unit * 2.5) 0 set-alpha('text', 0.1); // 0 10px 20px 0
  }

  &-Mobile {
    bottom: 0;
    padding-top: $ha-spacing-small;
    padding-bottom: $ha-spacing-small;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    box-shadow: 0 ($ha-unit * 0.25) $ha-unit 0 set-alpha('text', 0.1); // 0 2px 8px 0
  }

  &-Show {
    display: flex !important;
  }

  &Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &Left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: $ha-spacing-large;

    @include mediaQuery(900) {
      flex: 1 1 auto;
    }

    .CampaignHeader--Title {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  &Right {
    @include mediaQuery(900) {
      flex: 0 0 auto;
    }

    .CardNumbers {
      display: flex;
      justify-content: flex-end;

      &--Main {
        display: flex;
        flex-direction: row;
        width: 100%;
        white-space: nowrap;
      }

      &--Mobile {
        margin-right: $ha-spacing-large;
      }

      &--Data {
        display: none;

        @include mediaQuery(1200) {
          display: block;
        }
      }
    }
  }

  &Left,
  &Right {
    @include mediaQuery(1200) {
      flex: 1 1 ($ha-unit * 30); // 240px
      max-width: 50%;
    }
  }

  &--Content {
    display: flex;
  }
}
</style>
