import { getCustomColor } from '@/helpers/color'
import { defaultColor } from '@/constants'
import { haMoment, functions } from '@ha/helpers'
import { getSeoDescription, getSeoEventType } from '@/helpers/seo'
import useRouteParams from '@/composables/useRouteParams'
import useFormType from '~/composables/useFormType'
import useStoreData from '~/composables/useStoreData'
import useFormData from '~/composables/useFormData'

export default {
  setup() {
    const { step } = useRouteParams()
    const { isCrowdfunding, isDonation } = useFormType()
    const { organization, form } = useStoreData()
    const { stepName } = useFormData()
    return {
      step,
      isCrowdfunding,
      isDonation,
      organization,
      form,
      stepName
    }
  },
  computed: {
    organizationName() {
      return this.organization?.name || ''
    },

    isMultilingual() {
      return this.isDonation
    },

    metaTitle() {
      const formTitle = this.form?.title || ''

      if (this.step === 1) {
        if (formTitle.length <= 25 && this.organizationName && !this.isDonation) {
          return `${formTitle} - ${this.organizationName}`
        }
        return formTitle
      }

      // Cas pour step !== 1
      let baseTitle
      if (formTitle.length > 25) {
        baseTitle = `${formTitle} - ${this.organizationName} - HelloAsso`
      } else if (this.organizationName && !this.isDonation) {
        baseTitle = `${formTitle} - ${this.organizationName} - HelloAsso`
      } else {
        baseTitle = `${formTitle} - HelloAsso`
      }

      // Ajouter stepName pour les étapes autres que 1
      return `${this.stepName} - ${baseTitle}`
    },
    metaDescription() {
      const eventType = getSeoEventType(this.form.activityType)
      return getSeoDescription(
        this.form?.place?.city,
        eventType,
        this.organizationName,
        this.organization.type,
        this.form.description,
        this.form.formType,
        this.$i18n,
        this.metaTitle
      )
    },
    metaImage() {
      return functions.getFormVignette(this.form, this.vignetteSize, 'fit')
    },
    vignetteSize() {
      return {
        width: 500,
        height: 360
      }
    },
    metaViewport() {
      return {
        name: 'viewport',
        content: this.$device.isSafari
          ? 'initial-scale=1.0001, minimum-scale=1.0001, maximum-scale=1.0001'
          : 'width=device-width, initial-scale=1'
      }
    },
    metaRobotUnavailableAfter() {
      const unavailableAfterDate =
        !this.isCrowdfunding && this.form.endDate
          ? haMoment(this.form.endDate).format('YYYY-MM-DD')
          : ''
      return unavailableAfterDate
        ? {
            hid: 'robots',
            name: 'robots',
            content: `unavailable_after: ${unavailableAfterDate}`
          }
        : {}
    },
    metaRobotNoIndex() {
      const dontIndex = ['Private', 'Draft', 'Deleted', 'Disabled'].includes(this.form.state)
      return dontIndex ? { hid: 'robots', name: 'robots', content: 'noindex' } : {}
    },
    metaLink() {
      return this.isMultilingual
        ? [
            {
              rel: 'canonical',
              href: this.formUrl
            },
            {
              rel: 'alternate',
              hreflang: 'fr',
              href: this.formUrl
            },
            {
              rel: 'alternate',
              hreflang: 'en',
              href: `${this.formUrl}/en`
            }
          ]
        : [
            {
              rel: 'canonical',
              href: this.formUrl
            }
          ]
    },
    formUrl() {
      return this.form?.url || ''
    },
    formColor() {
      return getCustomColor(this.form.color || defaultColor)
    }
  },
  head() {
    return {
      link: this.metaLink,
      htmlAttrs: {
        style: this.formColor
      },
      title: this.metaTitle,
      meta: [
        this.metaViewport,
        {
          hid: 'description',
          name: 'description',
          content: this.metaDescription
        },
        this.metaRobotNoIndex,
        this.metaRobotUnavailableAfter,
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.metaImage
        },
        {
          hid: 'og:site_name',
          property: 'og:site_name',
          content: 'HelloAsso'
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.metaTitle
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'article'
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.formUrl
        },
        {
          hid: 'og:locale',
          property: 'og:locale',
          content: 'fr_FR'
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.metaDescription
        },
        {
          hid: 'twitter:title',
          property: 'twitter:title',
          content: this.metaTitle
        },
        {
          hid: 'twitter:image',
          property: 'twitter:image',
          content: this.metaImage
        }
      ]
    }
  }
}
