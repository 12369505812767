export enum AggFormErrorResource {
  ORGANIZATION = 'organization',
  FORM = 'form'
}

export enum AggFormErrorOrganizationState {
  DELETED = 'Supprime',
  TEST = 'Test',
  DISABLED = 'Desactive'
}

export enum aggFormErrorFormState {
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  DISABLED = 'DISABLED'
}

export interface AggFormOrganizationStateError {
  resource: AggFormErrorResource
  organizationState?: AggFormErrorOrganizationState
}

export interface AggFormStateError {
  resource: AggFormErrorResource
  formState?: aggFormErrorFormState
}

export type RedirectionCheckCallback = void | { code: number, path: string }

export function checkIfFormNeedsToBeRedirected (
  customData: AggFormOrganizationStateError | AggFormStateError,
  organizationSlug: string
): RedirectionCheckCallback {
  if (!customData || !organizationSlug) {
    return
  }

  const redirection: RedirectionCheckCallback = {
    code: 200,
    path: ''
  }

  if (customData.resource === AggFormErrorResource.ORGANIZATION) {
    if (!('organizationState' in customData)) {
      redirection.code = 301
      redirection.path = '/e/recherche'
      return redirection
    }

    switch (customData.organizationState) {
      case AggFormErrorOrganizationState.DELETED:
      case AggFormErrorOrganizationState.TEST:
        redirection.code = 301
        redirection.path = '/e/recherche'
        return redirection
      case AggFormErrorOrganizationState.DISABLED:
        redirection.code = 302
        redirection.path = '/e/recherche'
        return redirection
    }
  }

  if (customData.resource === AggFormErrorResource.FORM) {
    if (!('formState' in customData)) {
      redirection.code = 301
      redirection.path = `/associations/${organizationSlug}`
      return redirection
    }

    switch (customData.formState) {
      case aggFormErrorFormState.DELETED:
      case aggFormErrorFormState.DISABLED:
        redirection.code = 301
        redirection.path = `/associations/${organizationSlug}`
        return redirection
      case aggFormErrorFormState.DRAFT:
        redirection.code = 302
        redirection.path = `/associations/${organizationSlug}`
        return redirection
    }
  }
}
