<template>
  <div class="extraOptionContainer">
    <template v-for="(option, oi) in cartItem.extraOptions">
      <div class="extra-option-checkbox">
        <ExtraOptionCheckbox
        :key="option.id"
        :cart-item="cartItem"
        :extra-option="option"
        data-test="input-option"
      />
        <template v-for="(customField, optionFieldPosition) in option.customFields">
          <ValidationProvider
            v-if="isSelected(cartItem.id, option.id)"
            v-slot="{ errors, valid, pristine }"
            :key="customFieldId(cartItem.tierId, index, oi, optionFieldPosition)"
            :rules="getCustomFieldValidator(customField)"
            :name="customFieldName(cartItem.tierId, index, oi, optionFieldPosition)"
            :mode="setModeByType(customField.type)"
            slim
          >
            <FormField
              :id="customFieldId(cartItem.tierId, index, oi, optionFieldPosition)"
              :value="option.customFields[optionFieldPosition].value"
              :cart-id="cartId"
              :label="customFieldLabel(customField)"
              :name="customFieldName(cartItem.tierId, index, oi, optionFieldPosition)"
              :type="customField.type"
              :values="customField.values"
              :is-valid="!pristine ? valid : null"
              :errors="errors"
              data-testid="input-custom-field-option"
              @input="onChange(oi, optionFieldPosition, $event)"
            />
          </ValidationProvider>
        </template>
      </div>
    </template>
  </div>
</template>

<script setup>
import { useContext } from '@nuxtjs/composition-api'
import FormField from '@/components/globals/form-fields/FormField.vue'
import ExtraOptionCheckbox from '@/components/extra-options/partials/ExtraOptionCheckbox.vue'
import { ValidationProvider } from 'vee-validate'
import useExtraOptions from '@/components/extra-options/useExtraOptions'
import useParticipantInformation from '../participants/useParticipantInformation'

const props = defineProps({
  cartItem: Object,
  cartId: String,
  index: Number
})

const {
  methods: { updateStoreCart }
} = useParticipantInformation()
const { isSelected } = useExtraOptions()
const { $device } = useContext()

const onChange = (extraOptionIndex, customFieldIndex, e) => {
  updateStoreCart({
    cartItemIndex: props.cartItem.id,
    extraOptionIndex,
    customFieldIndex,
    key: 'value',
    value: e
  })
}

const getCustomFieldValidator = ({ isRequired, type }) => {
  const validators = []
  if (isRequired) {
    validators.push('REQUIRED')
  }

  if (type === 'Phone') {
    validators.push('PHONE')
  }

  if (type === 'Zipcode') {
    validators.push('ZIP_CODE')
  }

  if (type === 'Date') {
    validators.push('DATE')
  }

  return validators.join('|')
}

const setModeByType = type => {
  if ($device.isIos || $device.isMacOS) {
    return 'aggressive'
  }
  return ['Date', 'Phone'].includes(type) ? 'lazy' : 'aggressive'
}

const customFieldName = (tierId, index, extraOptionindex, customFieldIndex) =>
  `option-name-${tierId}-${index}-${extraOptionindex}-${customFieldIndex}`

const customFieldId = (tierId, index, extraOptionindex, customFieldIndex) =>
  `option-if-${tierId}-${index}-${extraOptionindex}-${customFieldIndex}`

const customFieldLabel = customField =>
  customField.isRequired ? `${customField.label} *` : customField.label
</script>

<script>
export default {}
</script>

<style lang="scss">
.extraOptionContainer {
  .extra-option-checkbox {
  &:not(:last-child) {
    margin-bottom: $ha-spacing-large;
  }

  .extra-option-checkbox__description {
    margin-bottom: $ha-spacing-small;
    padding-left: $ha-unit * 3.5;
    color: var(--ha-color-text-light);
    font-size: $ha-font-size-small;

    &-disabled {
      color: var(--ha-color-text-disabled);
      cursor: not-allowed;
    }
  }
}
}


.Option {
  flex-grow: 0;
  font-size: $ha-font-size-small;

  &--Label {
    margin-bottom: 0;
    word-break: break-word;

    &:hover {
      cursor: pointer;
    }
  }

  &--Subtitle {
    color: var(--ha-color-text-lightest);
    word-break: break-word;
  }

  + .Field {
    margin: 0 0 $ha-spacing-large $ha-spacing-big;
    padding: 0 0 $ha-spacing-mini $ha-spacing-medium;
    border-left: $ha-border-width-regular solid set-alpha(border, 0.5);
  }

  .Field--Inner {
    display: inline-flex;
    align-items: center;
  }

  .HaCheckbox {
    display: inline-flex;
  }
}
</style>
