<script setup>
import { computed } from 'vue'
import { useContext, onMounted } from '@nuxtjs/composition-api'
import { functions } from '@ha/helpers'
import { HaCheckbox, HaFormField } from '@ha/components'
import { ValidationProvider } from 'vee-validate'
import useExtraOptions from '@/components/extra-options/useExtraOptions'
import useParticipantInformation from '@/components/participants/useParticipantInformation'

const props = defineProps({
  extraOption: {
    type: Object,
    required: true
  },
  cartItem: {
    type: Object,
    required: true
  }
})

const { i18n } = useContext()
const { getRemainingNumber, isSelected } = useExtraOptions()
const {
  methods: { updateStoreCart }
} = useParticipantInformation()

const validationRules = computed(() => (props.extraOption.isRequired ? 'IS_TRUE' : ''))
const remainingNumber = computed(() =>
  getRemainingNumber(props.cartItem.tierId, props.extraOption.id)
)
const labelRemainingNumber = computed(() => {
  const hideEmptyWording =
    props.extraOption.isRequired &&
    isSelected(props.cartItem.id, props.extraOption.id) &&
    remainingNumber.value === 0
  if (hideEmptyWording) return ''
  return Number.isInteger(remainingNumber.value)
    ? ` (${i18n.tc('option.available', remainingNumber.value, {
        available: remainingNumber.value
      })})`
    : ''
})
const labelRequired = computed(() =>
  props.extraOption.isRequired ? ` ${i18n.t('option.required')}` : ''
)
const labelPrice = computed(() =>
  props.extraOption.price
    ? i18n.t('campaign.currencyUnit', [functions.convertToEuros(props.extraOption.price)])
    : i18n.t('option.free')
)
const labelVat = computed(() =>
  props.extraOption.vatRate
    ? ` (${i18n.t('option.vat', [props.extraOption.vatRate.toFixed(2)])})`
    : ''
)
const label = computed(
  () =>
    `${props.extraOption.label} : ${labelPrice.value}${labelRequired.value}${labelRemainingNumber.value}${labelVat.value}`
)
const isEmpty = computed(() =>
  Number.isInteger(remainingNumber.value)
    ? !remainingNumber.value && !isSelected(props.cartItem.id, props.extraOption.id)
    : false
)
const isDisabled = computed(() => isEmpty.value || (props.extraOption.isRequired && !isEmpty.value))

const onChange = isSelected => {
  updateStoreCart({
    cartItemIndex: props.cartItem.id,
    extraOptionIndex: props.cartItem.extraOptions.indexOf(props.extraOption),
    key: 'value',
    value: isSelected
  })
}

onMounted(() => {
  if (props.extraOption.isRequired) {
    updateStoreCart({
      cartItemIndex: props.cartItem.id,
      extraOptionIndex: props.cartItem.extraOptions.indexOf(props.extraOption),
      key: 'value',
      value: true
    })
  }
})
</script>

<template>
  <ValidationProvider
    v-slot="{ errors, valid, validated }"
    :rules="validationRules"
    tag="div"
    slim
  >
    <HaFormField :is-valid="validated ? valid : null">
      <HaCheckbox
        :value="isSelected(cartItem.id, extraOption.id)"
        :disabled="isDisabled"
        :is-valid="validated ? valid : null"
        class="checkboxOption"
        @change="onChange"
      >
        {{ label }}
      </HaCheckbox>
      <template #error>{{ errors?.[0] }}</template>
    </HaFormField>
    <div
      v-if="extraOption?.description"
      class="extra-option-checkbox__description"
      :class="{ 'extra-option-checkbox__description-disabled': isDisabled }"
    >
      {{ extraOption.description }}
    </div>
  </ValidationProvider>
</template>

<style lang="scss" scoped>
.checkboxOption {
  :deep(label) {
    font-weight: $ha-font-weight-semibold;
}
}
</style>
