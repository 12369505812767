<template>
  <div id="map" />
</template>

<script>
export default {
  name: 'Map',
  props: {
    coordinates: {
      type: Array,
      required: true
    }
  },
  mounted() {
    const script = document.createElement('script')

    if (this.coordinates) {
      /* eslint-disable no-undef */
      script.onload = () => {
        const map = new maplibregl.Map({
          container: 'map', // container id
          style: 'https://maps.helloasso.com/styles/bright/style.json', // styles url
          center: [this.coordinates[1], this.coordinates[0]], // [lng, lat]
          zoom: 16,
          attributionControl: false
        })

        map.addControl(
          new maplibregl.AttributionControl({ compact: true }),
          'bottom-right'
        )
        map.addControl(new maplibregl.NavigationControl(), 'top-left')

        new maplibregl.Marker({ color: '#F45554' })
          .setLngLat([this.coordinates[1], this.coordinates[0]])
          .addTo(map)
          .getElement().removeAttribute("aria-label")

        map.boxZoom.disable()
        map.scrollZoom.disable()
        map.doubleClickZoom.disable()
      }
      /* eslint-enable no-undef */
      script.type = 'text/javascript'
      script.src = 'https://unpkg.com/maplibre-gl@2.1.9/dist/maplibre-gl.js'
      document.head.appendChild(script)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/maplibre-gl.css';

#map {
  width: 100%;
  height: 100%;
}
</style>
