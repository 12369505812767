<script setup>
import { ValidationProvider } from 'vee-validate'
import { HaInput, HaFormField, HaSwitch, HaTooltip } from '@ha/components'
import useAbnormalEmailControl from '@/composables/useAbnormalEmailControl'
import { computed } from 'vue'

const props = defineProps({
  testId: { type: String, required: true }, // Use to find component in unit testing
  firstname: { type: String, required: true },
  lastname: { type: String, required: true },
  email: { type: String, default: null },
  useTierInfoForPayment: { type: Boolean }
})

const emit = defineEmits([
  'update:firstname',
  'update:lastname',
  'update:email',
  'update',
])

const { abnormalEmailWarning, hasAbnormalValues } = useAbnormalEmailControl(
  computed(() => props.email)
)

const onUpdateField = (key, event) => {
  emit(`update:${key}`, event)
  emit('update')
}
</script>

<template>
  <div>
    <div class="inputContainer">
      <validation-provider
        v-slot="{ errors, valid, validated }"
        name="firstName"
        :rules="// eslint-disable-next-line max-len
        `REQUIRED|NAME_CHARS|NAME_NO_NUMBERS|NAME_LATIN|NAME_CONSECUTIVE_CHARS|NAME_VOWELS|NAME_LENGTH|NAME_IS_FORBIDDEN|NAME_CONTAINS_FORBIDDEN|max_length:50`"
        slim
      >
        <HaFormField :is-valid="validated ? valid : null">
          <template #label> {{ $t('campaign.field.firstName') }} * </template>
          <HaInput
            :value="props.firstname"
            name="firstname"
            :max-length="50"
            :is-valid="validated ? valid : null"
            :errors="errors"
            data-test="input-firstname"
            @input="onUpdateField('firstname', $event)"
          />
          <template #error>
            {{ errors[0] }}
          </template>
        </HaFormField>
      </validation-provider>

      <validation-provider
        v-slot="{ errors, valid, validated }"
        :rules="// eslint-disable-next-line max-len
        `REQUIRED|NAME_CHARS|NAME_NO_NUMBERS|NAME_LATIN|NAME_CONSECUTIVE_CHARS|NAME_VOWELS|NAME_LENGTH|NAME_IS_FORBIDDEN|NAME_CONTAINS_FORBIDDEN|max_length:50`"
        name="lastname"
        slim
      >
        <HaFormField :is-valid="validated ? valid : null">
          <template #label> {{ $t('campaign.field.lastName') }} * </template>
          <HaInput
            :value="props.lastname"
            name="lastname"
            :max-length="50"
            :is-valid="validated ? valid : null"
            :errors="errors"
            data-test="input-lastname"
            @input="onUpdateField('lastname', $event)"
          />
          <template #error>
            {{ errors[0] }}
          </template>
        </HaFormField>
      </validation-provider>
    </div>
    <validation-provider
      v-if="props.useTierInfoForPayment"
      v-slot="{ errors, valid, validated }"
      class="email-field"
      rules="EMAIL|REQUIRED"
      name="email"
      slim
    >
      <HaFormField :is-valid="validated ? valid : null">
        <template #label>
          <span>
            <HaTooltip position="right" class="tooltip">
              <template #trigger>
                <button type="button" class="tooltip__trigger">
                  {{ $t('campaign.field.email') }} *
                  <fa class="tooltip__trigger__icon" icon="info-circle" />
                </button>
              </template>
              <template #content>
                <div class="tooltip__content">
                  {{ $t('tooltip.emailConfirmation') }}
                </div>
              </template>
            </HaTooltip>
          </span>
        </template>

        <HaInput
          name="email"
          :value="props.email"
          :max-length="250"
          :is-valid="validated ? valid : null"
          :errors="errors"
          data-test="input-email"
          @input="emit('update:email', $event)"
        />

        <template #error>
          <template v-if="errors?.length"> {{ errors[0] }} </template>
          <template v-else-if="hasAbnormalValues">
            {{ abnormalEmailWarning }}
          </template>
        </template>
      </HaFormField>
    </validation-provider>
  </div>
</template>

<style lang="scss" scoped>
:deep(.HaFormField) {
  margin-bottom: $ha-spacing-medium;
}

.inputContainer {
  @include mediaQuery(600) {
    display: flex;
    justify-content: space-between;

    .HaFormField {
      width: 100%;

      &:first-of-type {
        margin-right:  $ha-spacing-large;
      }
    }
  }

}

.email-field {
  margin-top: $ha-spacing-medium;
}

.tooltip {
  &__trigger {
    color: var(--ha-color-text-light);

    &__icon {
      color: var(--ha-color-primary);
    }
  }

  &__content {
    width: $ha-unit * 20; // 160px
    font-weight: $ha-font-weight-regular;

    @include mediaQuery(600) {
      width: $ha-unit * 30; // 240px
    }
  }
}
</style>
