<script setup>
import { computed } from 'vue'
import useRouteParams from '@/composables/useRouteParams'
import useTiers from '@/components/tiers/useTiers'
import useStoreData from '@/composables/useStoreData'
import {
  getMaxByCart,
  getMaxByForm,
  getMaxByTier,
  getMaxByUser,
  getMaxByExtraOption,
  getCartQuantity,
  getTierQuantity
} from '@/components/tiers/helpers/index.ts'

const props = defineProps({
  tier: { type: Object, required: true }
})

const { formType } = useRouteParams()
const { form } = useStoreData()
const { selectedTiers, bookedTiersFromCart } = useTiers()

const tierQuantity = computed(() => getTierQuantity(props.tier, selectedTiers.value))
const cartQuantity = computed(() => getCartQuantity(selectedTiers.value))

// case user already has a cart with "booked" tiers
const bookedTierQuantity = computed(() => getTierQuantity(props.tier, bookedTiersFromCart.value))

const maxByCart = getMaxByCart()
const maxByTier = computed(() => getMaxByTier(props.tier))
const maxByForm = computed(() => getMaxByForm(form.value))
const maxByUser = computed(() => getMaxByUser(props.tier))
const maxByExtraOption = computed(() => getMaxByExtraOption(props.tier))

const displayCartQuantity = computed(() => cartQuantity.value >= maxByCart)
const isErrorCartQuantity = computed(() =>
  tierQuantity.value ? cartQuantity.value > maxByCart : false
)

const displayTierQuantity = computed(() => {
  if (maxByTier.value == null) return false
  return props.tier.showMaxEntries ? true : tierQuantity.value >= maxByTier.value
})

const displayUserQuantity = computed(() => maxByUser.value != null)
const isErrorUserQuantity = computed(() => tierQuantity.value > maxByUser.value)

const displayFormQuantity = computed(
  () => maxByForm.value != null && cartQuantity.value >= maxByForm.value && tierQuantity.value
)
const isErrorFormQuantity = computed(() => cartQuantity.value > maxByForm.value)

const displayExtraOptionsQuantity = computed(() =>
  maxByExtraOption.value == null ? false : tierQuantity.value >= maxByExtraOption.value
)
const isErrorExtraOptionsQuantity = computed(() => {
  return tierQuantity.value > maxByExtraOption.value
})
</script>

<template>
  <div class="tier-item-description-quantity">
    <p
      v-if="displayCartQuantity"
      class="tier-item-description-quantity__item"
      :class="{ 'tier-item-description-quantity__item--error': isErrorCartQuantity }"
    >
      {{ $t(`tiers.${formType}.maxByCart`, { max: maxByCart }) }}
    </p>

    <p
      v-if="displayFormQuantity"
      class="tier-item-description-quantity__item"
      :class="{ 'tier-item-description-quantity__item--error': isErrorFormQuantity }"
    >
      <fa icon="ticket-alt" fixed-width />
      {{ $tc(`tiers.${formType}.maxByForm`, 0, { max: maxByForm }) }}
    </p>

    <p
      v-if="displayTierQuantity"
      class="tier-item-description-quantity__item"
    >
      <fa icon="ticket-alt" fixed-width />
      {{
        $tc(`tiers.${formType}.maxByTier`, maxByTier, {
          max: maxByTier
        })
      }}
    </p>
    <p
      v-if="maxByTier !== null && bookedTierQuantity > 0"
      class="tier-item-description-quantity__item"
    >
      {{
        $tc(`tiers.${formType}.bookedTiers`, bookedTierQuantity, { quantity: bookedTierQuantity })
      }}
    </p>

    <p
      v-if="displayUserQuantity"
      class="tier-item-description-quantity__item"
      :class="{ 'tier-item-description-quantity__item--error': isErrorUserQuantity }"
    >
      <fa icon="ticket-alt" fixed-width />
      {{ $t(`tiers.${formType}.maxByUser`, { max: maxByUser }) }}
    </p>

    <p
      v-if="displayExtraOptionsQuantity"
      class="tier-item-description-quantity__item"
      :class="{ 'tier-item-description-quantity__item--error': isErrorExtraOptionsQuantity }"
    >
      {{ $t(`tiers.${formType}.maxByExtraOption`, { max: maxByExtraOption }) }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
.tier-item-description-quantity {
  &__item {
    margin-top: $ha-spacing-small;
    margin-bottom: 0;
    color: var(--ha-color-text-light);
    font-weight: $ha-font-weight-semibold;
    font-size: $ha-font-size-small;

    &--error {
      color: var(--ha-color-danger);
    }

    svg {
      margin-right: $ha-spacing-mini;
    }
  }
}
</style>
