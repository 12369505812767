<script setup>
import { ref } from '@nuxtjs/composition-api'
import ModalReport from '@/components/reports/ModalReport.vue'
import { HaButton } from '@ha/components'

const displayModalReport = ref(false)
</script>

<template>
  <div>
    <HaButton
      data-test="action-report-btn"
      class="ActionReport--Button"
      color="danger"
      variant="flat"
      fluid
      :data-ux="`Forms_${metadata.type}_Report_Toggle_ModalReport`"
      @click="displayModalReport = true"
    >
      {{ $t('campaign.report') }}
       <fa icon="exclamation-triangle" aria-hidden="true" />
    </HaButton>
    <ModalReport v-if="displayModalReport" @close="displayModalReport = false" />
  </div>
</template>

<style lang="scss" scoped>
.ActionReport {
  &--Button {
    --ha-color-danger-h: 0;
    --ha-color-danger-s: 88%;
    --ha-color-danger-l: 64%;

    display: block;
    width: auto !important;
    margin-top: $ha-spacing-small;
    margin-inline: auto;

    &.HaButton-Flat.HaButton-Danger {
      color: var(--ha-color-midnight) !important; // should be red but we're waiting for color with good contrast

      svg {
        margin-left: $ha-unit; //8px
      }
    }

    &.HaButton-Flat.HaButton-Basic {
      color: var(--ha-color-text-light);

      &:focus {
        border-color: transparent !important;
        box-shadow: none !important;
      }

      svg {
        margin-left: $ha-unit; //8px
      }

      &:hover,
      &:active {
        color: var(--ha-color-text);
        background: none !important;
      }
    }
  }
}
</style>
