import { useSsrState } from '@ha/components'
import { Ref, computed, onMounted, useContext, useStore } from '@nuxtjs/composition-api'
import { getParticipantInformationFromTier } from '../participants/helper'
import useRouteParams from '../../composables/useRouteParams'
import useNotifications from '../../composables/useNotifications'
import useStoreData from '../../composables/useStoreData'
import useFormType from '../../composables/useFormType'
import { CartItem, CartItemAltered, CartUpdateValueArgs, CartsAction } from '../carts/carts.interface'
import { CollectionOf } from '../../../src/types/common.interface'
import { Tier } from '../tiers/tiers.interface'
import { CustomField } from '../custom-fields/customField.interface'

interface ParticipantInfo {
  firstname: string
  lastname: string
  email: string
  customFields: {
    model: {
      file: File | null
      value: string
    }
    validator: unknown
    meta: {
      forceError: string | null
      isLoading: boolean
    }
  }[]
}

const tierToUseForPaymentLocalKey: Ref<string | null> = useSsrState('tierToUseForPaymentLocalKey', () => null)
const localParticipantInformationFields: Ref<CollectionOf<ParticipantInfo>> = useSsrState('localParticipantInformationFields', () => ({}))

// todo : Boolean as quick fix for SF incident I-03379 (GP-53), bc pb comes from
// vee-validate not so good validation for with file input in CustomField component
const isFileUploading: Ref<boolean> = useSsrState('isFileUploading', () => false)
export default () => {
  const store = useStore()
  const { storeRouteParams } = useRouteParams()
  const { i18n } = useContext()
  const { onError } = useNotifications()
  const { cart, payer } = useStoreData()
  const { isShop, isCrowdfunding } = useFormType()

  const cartItems = computed<CartItem[]>(() => {
    return (
      cart.value?.itemList.map((item, index) => ({
        ...item,
        id: index,
        mail: payer.value?.email || item.email
      })) || []
    )
  })

  const cartItemCustomFields = computed(() => {
    const customFields: CustomField[] = []
    for (const item of cart.value?.itemList) {
      for (const field of item.customFields || []) {
        customFields.push(field)
      }
    }
    return customFields
  })

  const tierItems = computed<Tier[]>(() => cart.value?.tierList || [])

  const modelsByTierId = computed<CollectionOf<CartItem[]>>(() => {
    const groupedModels: CollectionOf<CartItem[] & CartItemAltered[]> = {}

    if (cartItems.value?.length) {
      for (const tier of cartItems.value) {
        const { tierId, tierType } = tier
        const isDonation = tierType === 'Donation'
        groupedModels[tierId] = groupedModels[tierId] || []

        // ! Do not keep for migration
        const mustRegisterParticipantInformation =
          !(isShop.value || isCrowdfunding.value || isDonation) || tier.customFields?.length

        if (mustRegisterParticipantInformation) {
          const participant = { ...tier, tierId }
          const participantLocalId = addParticipant(participant, groupedModels[tierId].length)
          groupedModels[tierId].push({ ...tier, participantLocalId })
        } else {
          groupedModels[tierId].push(tier)
        }
      }
    }

    return groupedModels
  })

  const localParticipantInformationFieldsLength = computed(
    () => Object.keys(localParticipantInformationFields.value).length
  )

  const addParticipant = (cartItem: CartItem, cartItemIndex: number) => {
    const localKey = `${cartItemIndex}-${cartItem.tierId}`
    const participantInformation: ParticipantInfo = getParticipantInformationFromTier(cartItem, localKey)
    localParticipantInformationFields.value[localKey] = localParticipantInformationFields.value[localKey] || { ...participantInformation }
    return localKey
  }

  const getParticipantInformation = (localKey: string): ParticipantInfo => {
    return localParticipantInformationFields.value[localKey]
  }

  const applyParticipantMandatoryInformationToAllTiers = () => {
    const values = Object.values(localParticipantInformationFields.value)

    if (!values.length) return

    const { firstname, lastname } = values[0] as ParticipantInfo

    for (const key in localParticipantInformationFields.value) {
      if (Object.hasOwn(localParticipantInformationFields.value, key)) {
        const participant = localParticipantInformationFields.value[key];
        participant.firstname = firstname
        participant.lastname = lastname
      }
    }
  }

  const onUseTierInfoForPayment = (participantLocalId: string) => {
    tierToUseForPaymentLocalKey.value =
      tierToUseForPaymentLocalKey.value === participantLocalId ? null : participantLocalId
  }

  const uploadFile = async (file: File) => {
    isFileUploading.value = true
    try {
      const fileName = await store.dispatch('carts/uploadFile', [cart.value.id, file])
      isFileUploading.value = false
      return fileName
    } catch (error: any) {
      isFileUploading.value = false
      const msg = i18n.t(`error.code.${error.statusCode}.title`)
      onError(error, {
        type: 'danger',
        title: i18n.t('error.upload'),
        body: msg,
        timeout: 5000
      })

      throw msg
    }
  }

  const optimisticUploadFile = async (localKey: string, customFieldIndex: number, file: File) => {
    const customFieldRef =
      localParticipantInformationFields.value[localKey].customFields[customFieldIndex]

    // Display result before to know if the request were successful, to ease UI error handling & better UX
    if (customFieldRef.meta.forceError) customFieldRef.meta.forceError = null
    customFieldRef.model.file = file
    customFieldRef.meta.isLoading = true

    try {
      const fileName = await uploadFile(file)
      customFieldRef.model.value = fileName
    } catch (e: any) {
      customFieldRef.model.file = null
      customFieldRef.meta.forceError = e.toString()
    } finally {
      customFieldRef.meta.isLoading = false
    }
  }

  const emptyLocalParticipantInformationFields = () => {
    if (!!localParticipantInformationFieldsLength.value) localParticipantInformationFields.value = {}
  }

  const hasOneItemRequired = computed(() => {
    if (!isShop || !isCrowdfunding) {
      return true
    }

    let hasOneItemRequired = false
    for (const field of cartItemCustomFields.value) {
      if (field.isRequired) {
        return true
      }
    }

    return hasOneItemRequired
  })

  const updateStoreCart = (args: CartUpdateValueArgs) => {
    const { organization, slug, type } = storeRouteParams.value
    try {
      store.dispatch(`carts/${CartsAction.UPDATE_VALUE}`, {
        ...args,
        organizationSlug: organization,
        formSlug: slug,
        formType: type,
      })
    } catch (error) {
      onError(error, {
        type: 'danger',
        title: i18n.t('error.cart.update.title'),
        body: i18n.t('error.cart.update.body'),
        timeout: 5000
      })
    }
  }

  onMounted(async () => {
    await store.getters['carts/getCart'](storeRouteParams.value)

    const cartId = store.getters['carts/getCart'](storeRouteParams.value)?.id
    const payerInformation = store.getters['payer/getPrefilledPayer'](cartId)

    if (payerInformation?.localKey) tierToUseForPaymentLocalKey.value = payerInformation.localKey
  })

  return {
    state: {
      cartItems,
      modelsByTierId,
      hasOneItemRequired,
      cartItemCustomFields,
      tierItems,
      localParticipantInformationFields,
      tierToUseForPaymentLocalKey,
      localParticipantInformationFieldsLength,
      isFileUploading,
    },
    methods: {
      addParticipant,
      getParticipantInformation,
      optimisticUploadFile,
      uploadFile,
      applyParticipantMandatoryInformationToAllTiers,
      onUseTierInfoForPayment,
      emptyLocalParticipantInformationFields,
      updateStoreCart,
    }
  }
}
