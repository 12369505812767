<template>
  <main :class="`${form.formType}`">
    <div class="banner-wrapper">
      <StepperBanner v-if="!isDonation && !isSale" />
    </div>
    <div class="CampaignWrapper Container">
      <FormPublishState v-if="isUnPublished" :state="form.state" />
      <StepperView
        v-if="!isDonation && !isSale"
        :step="step"
        :navigate="navigate"
        :current-step-component="getCurrentStepComponent()"
      />
      <ActionReport v-if="showActionReport" />
      <DonationView v-if="isDonation" />
      <SaleView v-if="isSale" />
    </div>
  </main>
</template>

<script>
import { UrlToApiTypes } from '@/helpers/enums'
import useFormType from '@/composables/useFormType'
import useStoreData from '@/composables/useStoreData'
import { FormSteps } from '@/helpers/formConfig'
import StepperBanner from '@/components/forms/stepper/partials/StepperBanner.vue'
import FormPublishState from '@/components/forms/informations/FormPublishState.vue'
import StepperView from '@/components/forms/stepper/StepperView.vue'
import DonationView from '@/components/forms/donations/DonationView.vue'
import SaleView from '@/components/forms/sales/SaleView.vue'
import useTracking from '@/composables/useTracking'
import { useHeaderPublic } from '@ha/components'
import nodeEnv from '@/helpers/nodeEnv.ts'
import { isUserConnected } from '@/helpers/auth'
import { getMicroData } from '@/helpers/seo'
import ActionReport from '@/components/reports/ActionReport.vue'
import { checkIfFormNeedsToBeRedirected } from '@/helpers/fetchFormsRedirections'
import useAmplitudeExperiment from '@/composables/useAmplitudeExperiment'

const debug = require('debug')('ha-forms:_steps')

export default {
  name: 'Steps',
  components: {
    StepperView,
    StepperBanner,
    FormPublishState,
    DonationView,
    SaleView,
    ActionReport
  },
  meta: { requiresAuth: false },
  props: {
    errorStatus: {
      type: [String, Number],
      required: false
    }
  },
  scrollToTop: false,
  setup() {
    const { form, organization, user } = useStoreData()
    const { isSale, isDonation, isShop, isCrowdfunding } = useFormType()
    const { trackingIdentify } = useTracking()
    const { setUser, setOrganizations, isLoadingUser } = useHeaderPublic()
    const { initExperiment } = useAmplitudeExperiment()

    const trackUser = () => {
      if (!user.value?.userId) return
      trackingIdentify(user.value.userId, {
        userId: user.value.userId
      })
    }

    trackUser()

    return {
      form,
      organization,
      user,
      isSale,
      isDonation,
      isShop,
      isCrowdfunding,
      setUser,
      setOrganizations,
      isLoadingUser,
      initExperiment
    }
  },
  asyncData({ params }) {
    // set data with route params (before rendering the comp)
    return {
      organizationSlug: params.organizationSlug?.replace(/\s/g, '-'),
      campaignSlug: params.campaignSlug?.replace(/\s/g, '-'),
      type: params.formType
    }
  },
  async fetch({ store, params, error, app, redirect }) {
    const orgSlug = params.organizationSlug?.replace(/\s/g, '-')

    // build "metadata" with get params of the route
    const metadata = {
      organization: orgSlug,
      slug: params.campaignSlug?.replace(/\s/g, '-'),
      type: UrlToApiTypes[params.formType]
    }

    try {
      await store.dispatch('forms/fetchFormIfNeeded', metadata)
    } catch (err) {
      if (err?.data?.errors?.[0]?.code === 'ResourceNotFound') {
        const data = err.data.errors[0].customData
        const organization = metadata.organization
        const redirection = checkIfFormNeedsToBeRedirected(data, organization)

        if (redirection) {
          await redirect(redirection.code, redirection.path)
        }
      }

      if (!nodeEnv.isProduction) debug(err)
      return error(err)
    }
  },
  computed: {
    formSteps() {
      if ((this.isShop || this.isCrowdfunding) && !this.hasCustomFieldsOrOptions) {
        return FormSteps[this.metadata.type].filter((item, index) => index !== 1)
      }
      return FormSteps[this.metadata.type]
    },
    hasCustomFieldsOrOptions() {
      return !!this.form?.tiers?.find(
        item => item.customFields?.length || item.extraOptions?.length
      )
    },
    step() {
      return parseInt(this.$route.params.steps, 10) || 1
    },
    baseCampaignUrl() {
      return `/associations/${this.organizationSlug}/${this.type}/${this.campaignSlug}`
    },
    isUnPublished() {
      return ['Draft', 'Disabled', 'Deleted'].includes(this.form?.state)
    },
    isFirstStep() {
      return this.step === 1
    },
    showActionReport() {
      return !this.isDonation && !this.isSale && this.isFirstStep
    }
  },
  jsonld() {
    return getMicroData(this.form, this.organization)
  },
  created() {
    // Available only for 'formulaires' -> donation
    if (this.$route.name === 'EnglishRoute') {
      this.$i18n.setLocale('en')
    } else {
      this.$i18n.setLocale('fr')
    }
  },
  async mounted() {
    // Script is declared in gtm
    this.$gtm.push('realytics-default')

    let user = this.$store.getters['user/getMe']()
    if (isUserConnected(this.$cookies)) {
      this.isLoadingUser = true
      if (!user?.userId) {
        await this.$store.dispatch('user/fetchMe')
        user = this.$store.getters['user/getMe']()
        this.setUser(user)
        const userOrganization = this.$store.getters['user/getUserOrganizations']()
        if (userOrganization.length) {
          this.setOrganizations(userOrganization)
          await this.$store.dispatch('forms/fetchForm', this.metadata)
        }
      }
      this.isLoadingUser = false
    }

    this.initExperiment('gp-1267-test', user?.userId)

    if (this.step > 1) {
      setTimeout(this.scrollIntoTargetElement, 0)
    }
  },
  methods: {
    /**
     * Wraps the $router `push` method to add our URL metadata
     * @param {number} nextStep
     */
    navigate(nextStep) {
      this.$router.push({
        path: `${this.baseCampaignUrl}/${nextStep}`
      })
    },
    getCurrentStepComponent() {
      if (this.step <= this.formSteps?.length) {
        return this.formSteps[this.step - 1]?.component
      }
      return this.formSteps[0]?.component
    },
    scrollIntoTargetElement() {
      if (typeof window == 'undefined') return
      const anchorElementName = this.$route.hash || '#anchorToTickets'
      const anchorOffsetTop =
        this.$el.querySelector(anchorElementName)?.getBoundingClientRect().top + window.scrollY
      const offsetTop = 10
      window.scrollTo({ top: anchorOffsetTop - offsetTop })
    }
  }
}
</script>

<style scoped lang="scss">
.PaymentForm {
  overflow-x: hidden;
}

.banner-wrapper {
  aspect-ratio: 1920 / 250;

  &:empty {
    display: none;
  }
}

main {
  :deep(.ActionReport--Button) {
    margin-top: calc($ha-spacing-big * 2);
  }
}
</style>
