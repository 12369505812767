import { haMoment } from '@ha/helpers'

/**
 * Difference in months between 2 dates
 * @param {Date} date1
 * @param {Date} date2
 * @returns {Date}
 */
export function differenceInMonths(date1, date2) {
  const result = date1.diff(date2, 'months', true)
  return result || 0
}

/**
 * Get an Array of every monthly terms date and their corresponding fee, between 2 dates.
 * @param {Date} date1
 * @param {Date} date2
 * @param {Number} amount amount of the term
 * @param {Number} termsDay day of the term
 * @param {String} validityType validity type of the form
 * @returns {Array} array of terms
 */
export function getTermsBetweenTwoDates(startDate, endDate, amount, termsDay, validityType) {
  const terms = []
  const currentMonth = startDate.month()
  const currentYear = startDate.year()

  let nbTerms = 10

  if (validityType === 'Custom') {
    const dayTermsEndDate = new Date(endDate)

    if (endDate.isSameOrBefore(haMoment(dayTermsEndDate).day(termsDay).startOf('day'), 'day')) {
      endDate = endDate.day(1).subtract(1, 'day')
    }
    nbTerms = Math.ceil(differenceInMonths(endDate, startDate.day(termsDay).startOf('day')))
    // show 12 terms max
    nbTerms = Math.min(nbTerms, 12)
  }

  for (let i = 0; i < nbTerms; i++) {
    terms.push({
      Amount: amount,
      Date: haMoment().year(currentYear).month(currentMonth).date(termsDay).add(i, 'months')
    })
  }

  return terms
}

/**
 * Get the number of remaining terms for a given Adhesion timeframe
 * @param {Date} Adhesion start date
 * @param {Date} Adhesion end date
 * @param {Number} Monthly term day
 * @returns {Number}
 */
export function getNumberOfRemainingTerms(adhesionStartDate, adhesionEndDate, termDay) {
  let termsAmount
  const lastTermDate = haMoment(adhesionEndDate).startOf('day').day(termDay)

  // customStartDate correspond to the Adhesion starting date
  // unless it has expired, so customStartDate becomes the current day (To avoid past months)
  const customStartDate = haMoment().isAfter(adhesionStartDate)
    ? haMoment().startOf('day')
    : haMoment(adhesionStartDate).day(termDay).startOf('day')

  // customEndDate correspond to the Adhesion ending date
  // unless it is the same day or before the very last term date, so it becomes a previous month date (To avoid the last month)
  const customEndDate = haMoment(adhesionEndDate).startOf('day').isSameOrBefore(lastTermDate, 'day')
    ? haMoment(adhesionEndDate).day(1).subtract(1, 'day')
    : haMoment(adhesionEndDate).startOf('day')

  termsAmount = Math.ceil(differenceInMonths(customEndDate, customStartDate))
  termsAmount = Math.min(termsAmount, 12)
  return termsAmount
}

/**
 * Get payment frequence from tier and form
 * @param {Object} tier
 * @param {Object} form
 * @returns {Number}
 */
export const getPaymentFrequency = (tier, form) => {
  if (tier?.paymentFrequency === 'Monthly') {
    const startDate = form?.startDate
    const endDate = form?.endDate

    switch (form?.validityType) {
      case 'MovingYear':
        return Math.ceil(
          haMoment(startDate).add(1, 'year').diff(haMoment(startDate), 'months', true)
        )
      case 'Custom': {
        const termsDate = new Date(tier.terms?.[0]?.date)
        return getNumberOfRemainingTerms(startDate, endDate, termsDate.getDate())
      }

      default:
        return 10
    }
  }
  if (tier?.paymentFrequency === 'Installment') {
    return 3
  }
  return 1
}
