import { computed } from 'vue'
import { useMeta, useContext } from '@nuxtjs/composition-api'
import { MetaInfo } from 'vue-meta'
import { getCustomColor } from '@/helpers/color'
import { defaultColor } from '@/constants'
import { haMoment, functions } from '@ha/helpers'
import useStoreData from '@/composables/useStoreData'
import useFormType from '@/composables/useFormType'
import { getSeoDescription, getSeoEventType } from '@/helpers/seo'
import useRouteParams from './useRouteParams'
import useFormData from './useFormData'
import { MetaData, LinksMeta } from './useMetaData.interface'

export default () => {
  const { form, organization } = useStoreData()
  const { isCrowdfunding, isDonation } = useFormType()
  const { step } = useRouteParams()
  const { stepName } = useFormData()
  const { $device, i18n } = useContext()
  const vignetteSize = {
    width: 500,
    height: 360
  }

  const organizationName = computed(() => {
    return organization.value.name || ''
  })
  const isMultilingual = computed(() => {
    return isDonation.value
  })
  const formUrl = computed(() => {
    return form.value.url || ''
  })

  const title = computed(() => {
    const formTitle = functions.capitalizeFirstLetter(form.value?.title || '')

    if (step.value === 1) {
      if (formTitle.length <= 25 && organizationName.value && !isDonation.value) {
        return `${formTitle} - ${organizationName.value}`
      }
      return formTitle
    }

    // Cas pour step !== 1
    let baseTitle: string
    if (formTitle.length > 25) {
      baseTitle = `${formTitle} - ${organizationName.value} - HelloAsso`
    } else if (organizationName.value && !isDonation.value) {
      baseTitle = `${formTitle} - ${organizationName.value} - HelloAsso`
    } else {
      baseTitle = `${formTitle} - HelloAsso`
    }

    // Ajouter stepName pour les étapes autres que 1
    return `${stepName.value} - ${baseTitle}`
  })
  const description = computed(() => {
    const eventType = getSeoEventType(form.value.activityType)
    return getSeoDescription(
      form.value.place?.city,
      eventType,
      organizationName.value,
      organization.value.type,
      form.value.description,
      form.value.formType,
      // @ts-ignore
      i18n,
      title.value
    )
  })
  const image = computed(() => {
    return functions.getFormVignette(form.value, vignetteSize, 'fit')
  })
  const viewport = computed(() => {
    return {
      name: 'viewport',
      content: $device.isSafari
        ? 'initial-scale=1.0001, minimum-scale=1.0001, maximum-scale=1.0001'
        : 'width=device-width, initial-scale=1'
    }
  })
  const robotUnavailableAfter = computed(() => {
    const unavailableAfterDate =
      !isCrowdfunding.value && form.value.endDate
        ? haMoment(form.value.endDate).format('YYYY-MM-DD')
        : ''
    return unavailableAfterDate
      ? {
          hid: 'robots',
          name: 'robots',
          content: `unavailable_after: ${unavailableAfterDate}`
        }
      : {}
  })
  const robotNoIndex = computed(() => {
    const dontIndex = ['Private', 'Draft', 'Deleted', 'Disabled'].includes(form.value.state)
    return dontIndex ? { hid: 'robots', name: 'robots', content: 'noindex' } : {}
  })

  const links = computed<LinksMeta[]>(() => {
    return isMultilingual.value
      ? [
          {
            rel: 'canonical',
            href: formUrl.value
          },
          {
            rel: 'alternate',
            hreflang: 'fr',
            href: formUrl.value
          },
          {
            rel: 'alternate',
            hreflang: 'en',
            href: `${formUrl.value}/en`
          }
        ]
      : [
          {
            rel: 'canonical',
            href: formUrl.value
          }
        ]
  })
  const formColor = computed(() => {
    return getCustomColor(form.value.color || defaultColor)
  })

  const metaData = computed<MetaData>(() => ({
    link: links.value,
    htmlAttrs: {
      style: formColor.value
    },
    title: title.value,
    meta: [
      viewport.value,
      {
        hid: 'description',
        name: 'description',
        content: description.value
      },
      robotNoIndex.value,
      robotUnavailableAfter.value,
      {
        hid: 'og:image',
        property: 'og:image',
        content: image.value
      },
      {
        hid: 'og:site_name',
        property: 'og:site_name',
        content: 'HelloAsso'
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: title.value
      },
      {
        hid: 'og:type',
        property: 'og:type',
        content: 'article'
      },
      {
        hid: 'og:url',
        property: 'og:url',
        content: formUrl.value
      },
      {
        hid: 'og:locale',
        property: 'og:locale',
        content: 'fr_FR'
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: description.value
      },
      {
        hid: 'twitter:title',
        property: 'twitter:title',
        content: title.value
      },
      {
        hid: 'twitter:image',
        property: 'twitter:image',
        content: image.value
      }
    ]
  }))

  const setMetaData = () => {
    useMeta(metaData.value as MetaInfo)
  }

  return {
    metaData,
    setMetaData
  }
}
