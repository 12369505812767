<template>
  <section class="CardOrganization--Contact CardContact">
    <span class="CardOrganization--Title">
      {{ $t('card.organization.contact.title_1') }}<br />
      {{ $t('card.organization.contact.title_2') }}
    </span>
    <div class="CardContactWrapper">
      <ha-button
        v-if="!isContactDisplayed"
        class="CardContact--InfosToggle"
        variant="link"
        :data-ux="`Forms_${metadata.type}_CardContact_Toggle_Infos`"
        @click="showContact"
      >
        {{ $t('card.organization.contact.showContact') }}
      </ha-button>
      <div v-if="isContactDisplayed" class="CardContact--Infos">
        <template v-if="email">
          <span class="CardOrganization--Subtitle">
            {{ $t('card.organization.contact.email') }}
          </span>
          <p class="CardContact--Info CardContact--Phone">
            {{ email }}
          </p>
        </template>
        <template v-if="contact.phoneNumber">
          <span class="CardOrganization--Subtitle">
            {{ $t('card.organization.contact.phone') }}
          </span>
          <p class="CardContact--Info CardContact--Phone">
            {{ contact.phoneNumber }}
          </p>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import { HaButton } from '@ha/components'
import { get } from 'lodash-es'

export default {
  name: 'CardContact',
  components: {
    HaButton
  },
  data() {
    return {
      isContactDisplayed: false
    }
  },
  computed: {
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    },
    contact() {
      return get(this.form, 'contact')
    },
    email() {
      return get(this.contact, 'email')
    }
  },
  methods: {
    async showContact() {
      this.isContactDisplayed = true
    }
  }
}
</script>

<style lang="scss">
.CardContact {
  &--InfosToggle {
    text-align: left;
  }

  &--Info:not(:last-of-type) {
    margin-bottom: $ha-spacing-tiny;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}
</style>
