<template>
  <div
    v-if="hasInformation"
    :class="['CampaignPresentation', 'Card', { 'isInvertedDescription' : needInverted }]"
    data-testid="campaign-presentation"
  >
    <div class="GeneratedContentWrapper">
      <!-- eslint-disable vue/no-v-html -->
      <div v-if="longDescription" class="'GeneratedContent" v-html="longDescription" />
    </div>
    <template v-if="projectExpensesDetails">
      <h3>{{ $t('campaign.projectExpensesDetails') }}</h3>
      <div class="GeneratedContent" v-html="projectExpensesDetails" />
    </template>
    <template v-if="projectBeneficiaries">
      <h3>{{ $t('campaign.projectBeneficiaries') }}</h3>
      <div class="GeneratedContent" v-html="projectBeneficiaries" />
    </template>
    <template v-if="projectOwners">
      <h3>{{ $t('campaign.projectOwners') }}</h3>
      <div class="GeneratedContent" v-html="projectOwners" />
    </template>
    <template v-if="projectTargetCountry">
      <h3 id="tickets">{{ $t('campaign.projectTargetCountry') }}</h3>
      {{ countryLabel }}
    </template>
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<script>
import { enum as enums } from '@ha/helpers'
import useFormType from '@/composables/useFormType'

export default {
  name: 'CampaignPresentation',
  props: {
    isInvertedDescription: {
      type: Boolean,
      value: false
    }
  },
  setup () {
    const { isCrowdfunding, isDonation } = useFormType()
    return {
      isCrowdfunding,
      isDonation
    }
  },
  computed: {
    hasInformation() {
      return (
        this.longDescription ||
        this.projectExpensesDetails ||
        this.projectBeneficiaries ||
        this.projectOwners ||
        this.projectTargetCountry
      )
    },
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    },
    formTypes() {
      return enums.FormType
    },
    longDescription() {
      return this.form?.longDescription?.trim()
    },
    projectExpensesDetails() {
      return this.form?.projectExpensesDetails
    },
    projectBeneficiaries() {
      return this.form?.projectBeneficiaries
    },
    projectOwners() {
      return this.form?.projectOwners
    },
    projectTargetCountry() {
      return this.form?.projectTargetCountry
    },
    countryLabel() {
      const country = enums.Country.find(item => item.code3 === this.projectTargetCountry)
      return country.label.fr
    },
    needInverted() {
      return this.isInvertedDescription || this.isCrowdfunding || this.isDonation
    }
  }
}
</script>

<style lang="scss">
/**
 * NOTE: Previously iso backoffice
 * Updated to get more a11y-friendly content lisibility
 */

.CampaignPresentation {
  // IE non-compatible nice property to replace the infamous clearfix hack
  display: flow-root;

  &:not(.isInvertedDescription) {
    margin: 0;
    padding: 0;
    box-shadow: none;
  }

  .GeneratedContentWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 0 !important;

    &,
    .GeneratedContent {
      margin: 0;
    }
  }

  * {
    font-weight: $ha-font-weight-regular;
    font-size: $ha-font-size-regular;
    word-wrap: break-word;

    &:empty {
      margin: 0;
    }
  }

  > *:first-child {
    &:not(.image),
    &:not(.media) {
      margin-top: $ha-spacing-medium;
    }
  }

  figure.image {
    display: table;
    clear: both;
    margin: $ha-spacing-medium auto;
    text-align: center;

    figcaption {
      display: table-caption;
      padding: $ha-spacing-tiny;
      color: var(--ha-color-text-light);
      font-size: $ha-font-size-small;
      word-break: break-word;
      caption-side: bottom;
      background-color: var(--ha-color-background);
      border-radius: $ha-radius-regular;
    }
  }

  div {
    position: relative;

    > img {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }

    br {
      display: none;
    }
  }

  h2,
  h2 > *,
  h3,
  h3 > *,
  h4,
  h4 > * {
    margin-top: $ha-spacing-large;
    margin-bottom: $ha-spacing-small;
    color: var(--ha-color-primary);
    font-weight: $ha-font-weight-semibold;
  }

  h2,
  h2 > * {
    font-size: $ha-font-size-giant;
  }

  h3,
  h3 > * {
    font-size: $ha-font-size-jumbo;
  }

  h4,
  h4 > * {
    font-size: $ha-font-size-large;
  }

  a {
    color: var(--ha-color-primary);
    font-weight: $ha-font-weight-semibold;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin-top: $ha-spacing-medium;
    margin-bottom: $ha-spacing-medium;
  }

  // b is used by legacy events already generated
  b,
  strong {
    font-weight: $ha-font-weight-semibold;
  }

  i {
    font-style: italic;
  }

  b i {
    font-weight: $ha-font-weight-semibold;
  }

  ul,
  ol {
    margin-top: $ha-spacing-large;
    margin-bottom: $ha-spacing-large;

    li {
      margin: $ha-spacing-tiny $ha-spacing-large $ha-spacing-tiny;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  li {
    // we use this rule to make sure we get the same behaviour accross various browsers
    // when changing the text-alignment of list items
    // otherwise, on chrome, the list markers would stay on the left, even though the text itself is aligned to the right
    list-style-position: inside;
  }

  figure {
    margin-top: $ha-spacing-jumbo;
    margin-bottom: $ha-spacing-jumbo;

    &.image-style-align-left,
    &.image-style-align-right {
      @include mediaQuery(600) {
        width: 50%;
      }
    }

    &.image-style-align-left {
      @include mediaQuery(600) {
        float: left;
        margin-right: $ha-spacing-jumbo;
      }
    }

    &.image-style-align-right {
      @include mediaQuery(600) {
        float: right;
        margin-left: $ha-spacing-jumbo;
      }
    }
  }

  img {
    border-radius: $ha-radius-large;
  }

  // We have to specify a height for Legacy forms
  // New ones with ck editor have a dedicated wrapper for that purpose
  iframe {
    display: block;
    width: 100% !important;
    height: $ha-unit * 30; // 240px
    margin: auto;

    @include mediaQuery(600) {
      height: $ha-unit * 40; // 320px
    }

    @include mediaQuery(900) {
      height: $ha-unit * 45; // 360px
    }

    @include mediaQuery(1200) {
      height: $ha-unit * 50; // 400px
    }
  }
}
</style>
