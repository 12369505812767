<script setup>
import { computed } from 'vue'
import { useContext } from '@nuxtjs/composition-api'
import { functions } from '@ha/helpers'
import TiersItemDescriptionQuantity from '@/components/tiers/partials/description/TiersItemDescriptionQuantity.vue'
import FiscalReductionHighlight from '@/components/globals/price/FiscalReductionHighlight.vue'
import useRouteParams from '@/composables/useRouteParams'
import useStoreData from '@/composables/useStoreData'
import useTiers from '@/components/tiers/useTiers'
import {
  getTierQuantity,
  getTierPrice,
  isTierCustomAmount
} from '@/components/tiers/helpers/index.ts'
import usePayerPersonalInformations from '@/components/payers/usePayerPersonalInformations'

const props = defineProps({
  tier: { type: Object, required: true }
})

const { i18n } = useContext()
const { organization } = useStoreData()
const { formType } = useRouteParams()
const { selectedTiers } = useTiers()
const { allowOnlyOrganism } = usePayerPersonalInformations()

const tierLabel = computed(() => props.tier.label || i18n.t('tiers.description.defaultTitle'))
const customAmountLabel = computed(() => {
  const minAmountEuro = props.tier.minAmount ? functions.convertToEuros(props.tier.minAmount) : 0
  return i18n.tc('tiers.description.customAmount', Math.round(minAmountEuro, 10), {
    min: i18n.t('globals.price', { price: minAmountEuro })
  })
})

const isEligibleTaxReceipt = computed(
  () => props.tier.isEligibleTaxReceipt && organization.value.fiscalReceiptEligibility
)
const fiscalReductionPrice = computed(() => {
  const tierQuantity = getTierQuantity(props.tier, selectedTiers.value)
  const tierPrice = getTierPrice(props.tier, selectedTiers.value)
  return (tierPrice || 0) * (tierQuantity || 1)
})

const isCompany = computed(() => {
  return allowOnlyOrganism.value
})
</script>

<template>
  <div class="tier-item-description">
    <p class="tier-item-description__title">
      {{ tierLabel }}
      <span
        v-if="isTierCustomAmount(props.tier)"
        class="tier-item-description__title--custom-amount"
      >
        - {{ customAmountLabel }}
      </span>
    </p>

    <p v-if="props.tier.description" class="tier-item-description__description">
      <span>{{ props.tier.description }}</span>
    </p>

    <FiscalReductionHighlight
      v-if="isEligibleTaxReceipt && fiscalReductionPrice"
      :price="fiscalReductionPrice"
      light-theme
      :is-company="isCompany"
    />

    <p v-if="props.tier.vatRate" class="tier-item-description__details">
      <fa icon="coins" fixed-width />
      {{ $t(`tiers.${formType}.vatRate`, { vat: props.tier.vatRate }) }}
    </p>

    <TiersItemDescriptionQuantity :tier="props.tier" />
  </div>
</template>

<style lang="scss" scoped>
.tier-item-description {
  @include mediaQuery(900) {
    display: flex;
    flex: 1 1 50%;
    flex-direction: column;
    font-size: $ha-font-size-regular;
    text-align: left;
  }

  &__title {
    margin: 0;
    color: var(--ha-color-text);
    font-weight: $ha-font-weight-semibold;
    font-size: $ha-font-size-small;
    word-break: break-word;

    @include mediaQuery(900) {
      font-size: $ha-font-size-large;
    }

    &::first-letter {
      text-transform: capitalize;
    }

    &--custom-amount {
      color: var(--ha-color-text-light);
      font-weight: $ha-font-weight-regular;
    }
  }

  &__description {
    margin: 0;
    color: var(--ha-color-text-light);
    font-weight: $ha-font-weight-regular;
    font-size: $ha-font-size-small;
    white-space: pre-line;
    word-break: break-word;
  }

  &__details {
    margin-top: $ha-spacing-small;
    margin-bottom: 0;
    color: #505870;
    font-weight: $ha-font-weight-semibold;
    font-size: $ha-font-size-small;

    svg {
      margin-right: $ha-spacing-mini;
    }
  }
}
</style>
