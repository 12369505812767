<script setup>
import MandatoryFields from '@/components/participants/partials/MandatoryFields.vue'
import useParticipantInformation from '@/components/participants/useParticipantInformation'
import { ref, computed, toRefs } from 'vue'
import { useStore, useRoute } from '@nuxtjs/composition-api'
import useFormType from '@/composables/useFormType'
import CustomField from '@/components/custom-fields/CustomField.vue'
import { HaSwitch } from '@ha/components'

const props = defineProps({
  participantLocalId: { type: [Number, String], required: true },
  displayCopyInfoBtn: { type: Boolean },
  hasMandatoryInformation: { type: Boolean }
})

const {
  state: { tierToUseForPaymentLocalKey },
  methods: {
    getParticipantInformation,
    applyParticipantMandatoryInformationToAllTiers,
    optimisticUploadFile,
    onUseTierInfoForPayment
  }
} = useParticipantInformation()
const store = useStore()
const { isShop, isCrowdfunding } = useFormType()
const route = useRoute()

const isUserConnected = computed(() => !!store.getters['user/getMe']().userId)

const { participantLocalId, displayCopyInfoBtn } = toRefs(props)
const localParticipantInformation = ref(getParticipantInformation(participantLocalId.value))
const applyInfoToAllParticipant = ref(displayCopyInfoBtn.value ? true : null)
const displayApplyInfoToggle = computed(() => !isShop.value && !isCrowdfunding.value && applyInfoToAllParticipant.value !== null)

const useTierInfoForPayment = computed(() => {
  if (isUserConnected.value) return null
  return props.participantLocalId === tierToUseForPaymentLocalKey.value
})

const onMandatoryFieldUpdate = () => {
  if (props.displayCopyInfoBtn && applyInfoToAllParticipant.value) {
    applyParticipantMandatoryInformationToAllTiers()
  }
}
const onApplyInfoToAllParticipants = value => {
  applyInfoToAllParticipant.value = value
  applyParticipantMandatoryInformationToAllTiers()
}
</script>

<script>
export default {
  name: 'ParticipantInformation'
}
</script>

<template>
  <div>
    <HaSwitch
      v-if="displayApplyInfoToggle"
      :value="applyInfoToAllParticipant"
      data-testid="toggle-copy-info-members"
      @change="onApplyInfoToAllParticipants"
    >
      {{ $t(`forms.${route.params.formType}CopyParticipantInformations`) }}
    </HaSwitch>

    <HaSwitch
      v-if="useTierInfoForPayment !== null && !isShop"
      :value="useTierInfoForPayment"
      data-testid="toggle-copy-info-payer"
      @change="onUseTierInfoForPayment(props.participantLocalId)"
    >
      {{ $t('campaign.field.payer') }}
    </HaSwitch>

    <MandatoryFields
      v-if="props.hasMandatoryInformation"
      :firstname="localParticipantInformation.firstname"
      :lastname="localParticipantInformation.lastname"
      :email="localParticipantInformation.email"
      :use-tier-info-for-payment="useTierInfoForPayment"
      :test-id="props.participantLocalId"
      @update="onMandatoryFieldUpdate"
      @update:firstname="localParticipantInformation.firstname = $event"
      @update:lastname="localParticipantInformation.lastname = $event"
      @update:email="localParticipantInformation.email = $event"
    />

    <CustomField
      v-for="(customField, index) in localParticipantInformation.customFields"
      :id="`${participantLocalId}-${index}`"
      :key="index"
      :validation="customField.validation"
      :model="customField.model"
      :meta="customField.meta"
      class="custom-field"
      @input="customField.model.value = $event"
      @update:file="optimisticUploadFile(props.participantLocalId, index, $event)"
    />
  </div>
</template>

<style lang="scss" scoped>

:deep(.HaSwitch){
  margin-bottom: $ha-spacing-medium;
}

.custom-field {
  margin-top: $ha-spacing-medium;
}

h5 {
  margin-top: $ha-spacing-medium;
}
</style>
